<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">Detalle de Transferencias</h4>
      </md-card-header>
      <md-card-content>
        <md-table v-model="tableDataDP">
          <md-table-toolbar>
            <md-field class="text-right">
              <!-- <md-button class="md-default" @click="Modalpersonalizado(4)"
                >Cargar Excel</md-button
              > -->
              <md-button class="md-default" @click="Modalpersonalizado(2)"
                >Nuevo</md-button
              >
              <md-button class="md-default" @click="Modalpersonalizado(1)"
                >Seleccionar</md-button
              >
              <md-button
                class="md-primary"
                @click="GenerarTransferenciaAutomatica()"
                >Generar transferencia Autom.</md-button
              >
            </md-field>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Id" v-if="isdebug">{{
              item.id
            }}</md-table-cell>
            <md-table-cell md-label="Tipo" v-if="isdebug">{{
              item.tipo
            }}</md-table-cell>
            <md-table-cell md-label="Importe">{{ item.importe }}</md-table-cell>
            <md-table-cell md-label="Referencias">{{
              item.referencias
            }}</md-table-cell>
            <md-table-cell md-label="Origen">{{ item.origen }}</md-table-cell>
            <md-table-cell md-label="Observaciones">{{
              item.observaciones
            }}</md-table-cell>
            <md-table-cell md-label="Fecha">{{ item.fecha }}</md-table-cell>
            <md-table-cell md-label="Acciones">
              <md-button
                class="md-just-icon md-danger md-round"
                @click="Modalpersonalizado(3, item)"
                ><md-icon>edit</md-icon></md-button
              >
              <md-button
                class="md-just-icon md-danger md-round"
                @click="Eliminar(item)"
                ><md-icon>close</md-icon></md-button
              >
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
    <modal v-if="classicModal" @close="classicModalHide">
      <template slot="header">
        <h4 class="modal-title">{{ datosModal.titulo }}</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="classicModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body" v-if="datosModal.evento == 1">
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
            @md-selected="onSelect"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>
            <md-table-row
              slot="md-table-row"
              slot-scope="{ item }"
              md-selectable="multiple"
            >
              <md-table-cell md-label="Id" v-if="isdebug">{{
                item.id
              }}</md-table-cell>
              <md-table-cell md-label="Tipo" v-if="isdebug">{{
                item.tipo
              }}</md-table-cell>
              <md-table-cell md-label="Importe">{{
                item.importe
              }}</md-table-cell>
              <md-table-cell md-label="Referencias">{{
                item.referencias
              }}</md-table-cell>
              <md-table-cell md-label="Origen">{{ item.origen }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </template>
      <template
        slot="body"
        v-if="datosModal.evento == 2 || datosModal.evento == 3"
      >
        <md-field v-if="isdebug">
          <label>Id</label>
          <md-input v-model="transferencia.id" disabled></md-input>
          <span class="md-helper-text">Id</span>
        </md-field>
        <!-- <md-field :class="transferencia.tipo">
          <label>Tipo</label>
          <md-input v-model="transferencia.tipo"></md-input>
          <span class="md-helper-text">Tipo</span>
        </md-field> -->
        <md-field>
          <label>Importe</label>
          <md-input v-model="transferencia.importe"></md-input>
          <span class="md-helper-text">Importe</span> </md-field
        ><md-field>
          <label>Referencias</label>
          <md-input v-model="transferencia.referencias"></md-input>
          <span class="md-helper-text">Referencias</span>
        </md-field>
        <md-field>
          <label>Origen</label>
          <md-input v-model="transferencia.origen"></md-input>
          <span class="md-helper-text">Origen</span> </md-field
        ><md-field>
          <label>Observaciones</label>
          <md-textarea v-model="transferencia.observaciones"></md-textarea>
          <span class="md-helper-text">Observaciones</span>
        </md-field>
        <md-field>
          <md-input v-model="transferencia.fecha" type="date">
            <label>Fecha</label>
          </md-input></md-field
        >
      </template>
      <template slot="body" v-if="datosModal.evento == 4">
        <div class="file-input">
          <div v-if="NameFile == null || NameFile == ''">
            <h5>Sin archivo</h5>
          </div>
          <div v-else>
            <h5>Se cargo el archivo: {{ NameFile }}</h5>
          </div>
          <div class="button-container">
            <md-button
              class="md-danger md-round"
              @click="removeImage"
              v-if="imageRegular"
              ><i class="fa fa-times"></i>Eliminar</md-button
            >
            <md-button class="md-success md-round md-fileinput">
              <template v-if="!imageRegular">Seleccionar archivo</template>
              <template v-else>Cambiar</template>
              <input type="file" @change="onFileChange" />
            </md-button>
          </div>
        </div>
      </template>
      <template slot="footer">
        <md-button class="md-danger md-simple" @click="classicModalHide"
          >Cerrar</md-button
        >
        <md-button class="md-primary" @click="acciones()">{{
          datosModal.boton
        }}</md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import serdetallemovimiento from "../../../Servicios/Movimientos/detallemovimientos";
import Fuse from "fuse.js";
import store from "../../../Store/index";
import format from "date-fns/format";
import XLSX from "xlsx";
import servDetalleMovimiento from "../../../Servicios/Movimientos/detallemovimientos";
import Swal from "sweetalert2";

export default {
  components: {
    Modal,
    Pagination,
    //Template,
  },
  props: {
    regularImg: {
      type: String,
      default: "./img/image_placeholder.jpg",
    },
    avatarImg: {
      type: String,
      default: "./img/placeholder.jpg",
    },
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    Movimientos() {
      return store.state.Movimientos;
    },
  },
  data() {
    return {
      dateFormat: "dd/mm/aaaa",
      classicModal: false, //Modal
      tableData: [], //Detalle Poliza Modal
      tableDataDP: [], //Detalle de Póliza
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      searchQuery: "",
      currentSort: "detallePolizaId",
      currentSortOrder: "asc",
      isLoader: true,
      searchedData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10],
        total: 0,
      },
      selected: [],
      mens: {
        tipo: "",
        mensaje: "",
      },
      transferencia: {
        id: 0,
        tipo: 0,
        importe: 0,
        referencias: null,
        origen: null,
        observaciones: null,
        fecha: "",
      },
      imageRegular: "",
      NameFile: "",
      ExceltoJSON: [],
      ExceltoJSONRAW: {},
      transId: 0,
    };
  },
  methods: {
    async GenerarExcelAJSON(e) {
      var file = e.target.files ? e.target.files[0] : null;
      const reader = new FileReader();
      if (file) {
        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          this.ExceltoJSONRAW = XLSX.utils.sheet_to_json(ws, { header: 1 });
          if (this.ExceltoJSONRAW.length > 0) {
            //console.log("Cantidad: ", this.ExceltoJSONRAW.length);
            const fecha = Date.now();
            const hoy = new Date(fecha);
            var cantidad = 0;
            this.ExceltoJSONRAW.forEach((row) => {
              var deposito = row[2];
              var auxFecha = row[0];
              var tipoFecha = typeof auxFecha;
              var Cfecha = tipoFecha == "string" ? auxFecha : hoy.toISOString();
              if (deposito > 0) {
                let obj = {
                  Fecha: Cfecha,
                  Descripcion: row[1],
                  Deposito: deposito,
                  Retiros: row[3],
                  Saldo: row[4],
                };
                this.ExceltoJSON.push(obj);
                cantidad++;
              }
            });
            console.log("Cantidad fin ", cantidad);
            console.log(this.ExceltoJSON);
          }
        };
        reader.readAsBinaryString(file);
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.GenerarExcelAJSON(e);
      this.NameFile = files[0].name;
    },
    createImage(file, type) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        if (type === "circle") {
          vm.imageCircle = e.target.result;
        } else {
          vm.imageRegular = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (type) {
      if (type === "circle") {
        this.imageCircle = "";
      } else {
        this.imageRegular = "";
      }
    },
    Eliminar(item) {
      store.state.Movimientos.Transferencias = [];
      console.log("Antes", this.tableDataDP);
      let findIndex = this.tableDataDP.findIndex(
        (a) => a.detallePolizaId === item.detallePolizaId
      );
      if (findIndex !== -1) {
        this.tableDataDP.splice(findIndex, 1);
      }
      console.log("despues", this.tableDataDP);
      store.state.Movimientos.Transferencias = this.tableDataDP;
      this.mens.tipo = "success";
      this.mens.mensaje = "Se han descartado correctamente el registro";
      //this.notifyVue(this.mens);
    },
    onSelect(items) {
      this.selected = [];
      this.selected = items;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    acciones() {
      switch (this.datosModal.evento) {
        case 1:
          if (this.selected.length > 0) {
            console.log('this.tableData',this.tableData);
            console.log('this.selected',this.selected);
             console.log('this.tableDataDP',this.tableDataDP);
            let DT = [];
            DT = this.tableData;
            this.selected.forEach((i) => {
              this.tableDataDP.push(i);
              let findIndex = DT.findIndex(
                (a) => a.detallePolizaId === i.detallePolizaId
              );
              if (findIndex !== -1) {
                DT.splice(findIndex, 1);
              }
            });
            this.tableData = [];
            this.tableData = DT;
            store.state.Movimientos.Transferencias = this.tableDataDP;
            this.mens.tipo = "success";
            this.mens.mensaje = "Se han agregado los registros seleccionados";
            //this.notifyVue(this.mens);
            this.classicModalHide();
          } else {
            this.mens.tipo = "danger";
            this.mens.mensaje =
              "Para continuar tiene que seleccionar almenos un detalle de póliza";
            this.notifyVue(this.mens);
          }
          break;
        case 2:
          //console.log(this.transferencia);
          serdetallemovimiento
            .post(this.transferencia)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            })
            .catch((e) =>
              console.log("Error: serdetallemovimiento-put-transferencias", e)
            );
          break;
        case 3:
          serdetallemovimiento
            .put(this.transferencia.id, this.transferencia)
            .then((r) => {
              this.ProcesamosResponse(r.data);
            })
            .catch((e) =>
              console.log("Error: serdetallemovimiento-put-transferencias", e)
            );
          break;
        case 4:
          if (this.ExceltoJSON.length > 0) {
            servDetalleMovimiento
              .CargaMasiva(this.ExceltoJSON)
              .then((r) => {
                this.ProcesamosResponse(r.data);
              })
              .catch((e) =>
                console.log("Error: servDetalleMovimiento-post-CargaMasiva", e)
              );
          } else {
            this.mens.tipo = "danger";
            this.mens.mensaje =
              "Para continuar el excel debe de contener información";
            this.notifyVue(this.mens);
          }
          break;
      }
    },
    classicModalHide() {
      this.classicModal = false;
    },
    Modalpersonalizado(tipo, item) {
      switch (tipo) {
        case 1: //Detalle de póliza
          this.GetAll();
          this.datosModal.titulo = "Listado de Detalle de Transferencias";
          this.classicModal = true;
          this.datosModal.evento = tipo;
          this.datosModal.boton = "Agregar";
          break;
        case 2: //Crear nueva transferencia
          this.datosModal.titulo = "Crear nueva transferencia";
          this.classicModal = true;
          this.datosModal.evento = tipo;
          this.datosModal.boton = "Guardar";
          this.selected = [];
          break;
        case 3: //Actualizar nueva transferencia
          this.transferencia.id = item.id;
          this.transferencia.tipo = item.tipo;
          this.transferencia.importe = item.importe;
          this.transferencia.referencias = item.referencias;
          this.transferencia.origen = item.origen;
          this.transferencia.observaciones = item.observaciones;
          // this.transferencia.fecha = format(item.fecha, this.dateFormat);
          this.datosModal.titulo = "Actualizar transferencia";
          this.classicModal = true;
          this.datosModal.evento = tipo;
          this.datosModal.boton = "Actualizar";
          break;
        case 4: //Cargar Excel
          this.datosModal.titulo = "Cargar excel de transferencias";
          this.classicModal = true;
          this.datosModal.evento = tipo;
          this.datosModal.boton = "Cargar";
          break;
      }
    },
    async GetAll() {
      await serdetallemovimiento
        .MoviDetalleMovimiento()
        .then((r) => {
          this.tableData = [];
          this.tableData = r.data;
          //console.log(this.tableData);
          if (this.tableDataDP.length > 0) {
            //ivertiremos la logica por si hay cambios siempre se tenga la version de la BD
            let auxtableDataDP = [];
            auxtableDataDP = this.tableDataDP;
            this.tableDataDP = [];
            auxtableDataDP.forEach((dp) => {
              this.tableData.forEach((t) => {
                if (dp.id == t.id) {
                  this.tableDataDP.push(t);
                }
              });
              let findIndex = this.tableData.findIndex((a) => a.id === dp.id);
              if (findIndex !== -1) {
                this.tableData.splice(findIndex, 1);
              }
            });
          }
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["polizaId", "categorias.descripcion"],
            threshold: 0.3,
          });
          this.isLoader = false;
        })
        .catch((e) => {
          console.log("detalle-transferencias-movimientos-GETALL", e);
          this.isLoader = false;
        });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 1000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "danger";
            this.notifyVue(this.mens);
            break;
          case 3: //success
            this.tableData = [];
            this.GetAll();
            this.classicModalHide();
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "success";
            this.notifyVue(this.mens);
            break;
          case 4: //Information
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "info";
            this.notifyVue(this.mens);
            break;
          case 5: //Parametros
            this.transId = parseInt(e.mensaje);
            if (this.transId > 0) {
              this.GetAll().then((c) => {
                this.datosModal.evento = 1;
                this.tableData.forEach((a) => {
                  if (a.id === this.transId) {
                    this.selected.push(a);
                  }
                });
                this.acciones();
              });
            }
            break;
        }
      });
    },
    GenerarTransferenciaAutomatica() {
      if (this.Movimientos.DetallePolizas.length > 0) {
        var cantidad = 0;
        this.Movimientos.DetallePolizas.forEach((dp) => {
          cantidad += dp.importe;
        });
        if (cantidad > 0) {
          Swal.fire({
            title: "!Generar transferencia automatica!",
            text: `¿Estas seguro de generar la siguiente transferencia por tal monto: ${cantidad} ?`,
            type: "success",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Si, generar!",
            cancelButtonText: "Cancelar",
            buttonsStyling: false,
          })
            .then((r) => {
              if (r.value) {
                let bandera = false;
                this.tableDataDP.forEach((t) => {
                  if (t.importe == cantidad) {
                    bandera = true;
                  }
                });
                if (bandera) {
                  this.mens.tipo = "info";
                  this.mens.mensaje =
                    "Se se genero un movimiento previo y se encuentra agregado para realizar el corte";
                  this.notifyVue(this.mens);
                } else {
                  let fecha = new Date().toISOString().slice(0, 10);
                  this.transferencia.id = 0;
                  this.transferencia.tipo = 1; //Entrada
                  this.transferencia.importe = cantidad;
                  this.transferencia.referencias = "Pago de Regalias";
                  this.transferencia.origen = "Por definir";
                  this.transferencia.observaciones = "Por definir";
                  this.transferencia.fecha = fecha;
                  this.datosModal.evento = 2;
                  this.selected = [];
                  this.acciones();
                }
              }
              if (r.dismiss == "cancel") {
                this.Clear();
              }
            })
            .catch(function (e) {
              console.log("create-transferencia-automatic", e);
            });
        }
      } else {
        this.mens.tipo = "danger";
        this.mens.mensaje = "Se necesita tener mas de un detalle de póliza";
        this.notifyVue(this.mens);
      }
    },
  },
  created() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style>
</style>