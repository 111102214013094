<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Listas Negras</h4>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="ModalPersonalizado(1)"
                  >Nuevo</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" md-sort-by="listaNegraId">{{ 
                item.listaNegraId
              }}</md-table-cell>
              <md-table-cell md-label="Nombres" md-sort-by="nombres">{{
                item.nombres }}
              </md-table-cell>
                <md-table-cell
                md-label="Paterno"
                md-sort-by="apellidoPaterno"
                >{{ item.apellidoPaterno }}</md-table-cell>
                <md-table-cell
                md-label="Materno"
                md-sort-by="apellidoMaterno"
                >{{ item.apellidoMaterno }}</md-table-cell>
                <md-table-cell
                md-label="Razón Social"
                md-sort-by="razonSocial"
                >{{ item.razonSocial }}</md-table-cell>
                <md-table-cell
                md-label="RFC"
                md-sort-by="rfc"
                >{{ item.rfc }}</md-table-cell>
                <md-table-cell
                md-label="Observaciones"
                md-sort-by="observaciones"
                >{{ item.observaciones }}</md-table-cell>
                <md-table-cell
                md-label="Estatus"
                md-sort-by="estatus"
                >{{ item.estatus }}</md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-warning md-simple"
                  @click.native="ModalPersonalizado(2, item)"
                  style="100px"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="ModalPersonalizado(3, item)"
                  style="50px"
                >
                  <md-icon>close</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="listaNegraId" disabled></md-input>
            <span class="md-helper-text">Id</span> </md-field
          ><md-field>
            <label>Nombres</label>
            <md-input v-model="nombres"></md-input>
            <span class="md-helper-text">Nombres</span>
          </md-field>
          <md-field>
            <label>Paterno</label>
            <md-input v-model="apellidoPaterno" ></md-input>
            <span class="md-helper-text">paterno</span> </md-field
          ><md-field>
            <label>Materno</label>
            <md-input v-model="apellidoMaterno" ></md-input>
            <span class="md-helper-text">materno</span> </md-field
          ><md-field>
            <label>Razon Social</label>
            <md-input v-model="razonSocial" ></md-input>
            <span class="md-helper-text">razonsocial</span> </md-field
          ><md-field>
            <label>RFC</label>
            <md-input v-model="rfc" ></md-input>
            <span class="md-helper-text">rfc</span> </md-field
          ><md-field>
            <label>Observaciones</label>
            <md-input v-model="observaciones" ></md-input>
            <span class="md-helper-text">observaciones</span> </md-field
          ><md-field>
            <md-switch v-model="estatus">Estatus: activo/inactivo</md-switch>
          </md-field>

        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow(datosModal.evento)"
            >{{ datosModal.boton }}</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import servlistasnegras from "../../Servicios/Representacion/listasnegras";

export default {
  components: {
    Pagination,
    Modal,
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentSort: "listaNegraId",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50,100],
        total: 0,
      },
      footerTable: ["Id", "Nombres","Paterno","Materno","Razón Social", "RFC","Observaciones", "Estatus","Acciones"],
      searchQuery: "",
      propsToSearch: ["listaNegraId", "nombres","apellidoPaterno","apellidoMaterno",
      "razonSocial","rfc","observaciones","estatus"],
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      classicModal: false,
      listaNegraId: 0,
      nombres: null,
      apellidoPaterno: null,
      apellidoMaterno: null,
      razonSocial: null,
      rfc: null,
      observaciones: null,
      estatus: null,

      datosModal: {
        titulo: "Crear nuevo registro",
        boton: "Confirmar",
        evento: 0,
      },
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    postNow(exp) {
      let data = {
        listaNegraId: this.listaNegraId,
        nombres: this.nombres,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        razonSocial: this.razonSocial,
        rfc: this.rfc,
        observaciones: this.observaciones,
        estatus: this.estatus,
        
      };
      switch (exp) {
        case 1:
          servlistasnegras
            .post(data)
            .then((r) => {
              //hay que cerrar el model
              this.ProcesamosResponse(r.data);
              //Aqui recuperamos los datos
            })
            .catch(function (e) {
              mensaje.Error("tiporefpersonal-crud-post", e);
            });
          break;

        case 2:
          if (this.listaNegraId > 0) {
            servlistasnegras
              .put(this.listaNegraId, data)
              .then((r) => {
                //hay que cerrar el model
                this.ProcesamosResponse(r.data);
                //Aqui recuperamos los datos
              })
              .catch(function (e) {
                mensaje.Error("tiporefpersonal-crud-put", e);
              });
          }
          break;
      }
    },
    getDatos() {
      servlistasnegras
        .all()
        
        .then((a) => {
          this.tableData = a.data;
          console.log(a);
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["listaNegraId", "rfc", "nombres", "apellidoPaterno", "apellidoMaterno"],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          mensaje.Error("usuario-area-all", e);
        });
    },
    classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
    ModalPersonalizado(exp, row) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo = "Agregar registro";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Actualizar
          this.listaNegraId = row.listaNegraId;
          this.nombres = row.nombres;
          this.apellidoPaterno = row.apellidoPaterno;
          this.apellidoMaterno = row.apellidoMaterno;
          this.razonSocial = row.razonSocial;
          this.rfc = row.rfc;
          this.observaciones = row.observaciones;
          this.estatus = row.estatus;
          this.classicModal = true;
          this.datosModal.titulo = "Actualizar registro";
          this.datosModal.boton = "Actualizar";
          this.datosModal.evento = 2;
          break;

        case 3: //Eliminar
          this.listaNegraId = row.listaNegraId;
          this.deleteRow();
          break;
      }
    },
    deleteRow() {
      if (this.listaNegraId > 0) {
        Swal.fire({
          title: "!Eliminar!",
          text: `¿Deseas eliminar el siguiente registro: ${this.listaNegraId} ?`,
          type: "success",
          showCancelButton: true,
          confirmButtonClass: "md-button md-success",
          cancelButtonClass: "md-button md-danger",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
          buttonsStyling: false,
        })
          .then((r) => {
            if (r.value) {
              servlistasnegras
                .delete(this.listaNegraId)
                .then((response) => {
                  Swal.fire({
                    title: "Eliminado!",
                    text: `Se ha eliminado el registro ${this.listaNegraId}`,
                    type: "success",
                    confirmButtonClass: "md-button md-success",
                    cancelButtonText: "Cancelar",
                    buttonsStyling: false,
                  });
                  let mens = {};
                  mens = mensaje.generico(
                    response.config.method,
                    response.status
                  );
                  if (mens.accion == true) {
                    this.getDatos();
                  }
                  this.notifyVue(mens);
                })
                .catch(function (e) {
                  mensaje.Error("usuario-crud-servlistasnegras-delete", e);
                });
            }
            if (r.dismiss == "cancel") {
              this.Clear();
            }
          })
          .catch(function (e) {
            mensaje.Error("usuario-crud-servlistasnegras-delete", e);
          });
      }
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    Clear() {
      this.listaNegraId = 0;
      this.nombres = null;
      this.apellidoPaterno = null;
      this.apellidoMaterno = null;
      this.razonSocial = null;
      this.rfc=null;
      this.observaciones=null;
      this.estatus=null;
    },
     ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.isActivo = false;
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.tableData = [];
            this.getDatos();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },
  mounted() {
    console.log("Muy bien gracias");
  },
  created() {
    this.getDatos();
    console.log("Hola como estas:");
  },
  
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        // console.log(value);
        // console.log(this.searchQuery);
        console.log(this.fuseSearch);
        result = this.fuseSearch.search(this.searchQuery);
        // console.log(result);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
.full-control > .md-list {
  width: 320px;
  max-width: 100%;
  height: 400px;
  display: inline-block;
  overflow: auto;
  border: 1px solid rgba(#000, 0.12);
  vertical-align: top;
}
</style>
