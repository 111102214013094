<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Correos</h4>
        </md-card-header>
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover" @md-selected="onSelect">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" style="margin-top: 20PX;" @click="agruparTramites()">
                   Agrupar Correos
                </md-button>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
              <md-table-cell md-label="Id" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Remitente" md-sort-by="fromName">{{ item.nombreRemitente }}</md-table-cell>
              <md-table-cell md-label="Correo" md-sort-by="fromAddress">
                <div class="multi-line-cell">
                  {{ item.direccionRemitente }}
                </div>
              </md-table-cell>
              <md-table-cell md-label="Asunto" md-sort-by="subject">{{ item.asunto }}</md-table-cell>
              <md-table-cell md-label="Fecha" md-sort-by="date">{{ item.fecha }}</md-table-cell>
              <md-table-cell style="text-align: center;" md-label="Archivos" md-sort-by="attachments">{{ item.totalAdjuntos }}</md-table-cell>
              <md-table-cell style="text-align: center;" md-label="Numero de Tramite" md-sort-by="attachments">{{ item.numeroDeTramite }}</md-table-cell>
              <md-table-cell md-label="Agrupar correos">
                <div class="large-centered-checkbox" v-if="item.numeroDeTramite == 0">
                  <input type="checkbox" style="cursor: pointer; justify-content: center; align-items: center;" v-model="item.agrupacion" @change="onCheckboxChange(item)">
                </div>
              </md-table-cell>
              <md-table-cell >
                <div v-if="item.numeroDeTramite != 0">
                  <button 
                    class="btn-red" 
                    @click="quitarAgrupacion(item.id)">
                      Quitar Agrupamiento
                  </button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">Agrupar Correos</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <md-field>
            <label>Id</label>
            <md-input v-model="TramiteId" disabled></md-input>
            <span class="md-helper-text">Id</span> </md-field
          ><md-field>
            <label>No. Tramite</label>
            <md-input v-model="NumeroTramite"></md-input>
            <span class="md-helper-text">No. Tramite</span>
          </md-field>
          <div class="autocomplete">
            <div style="font-size: smaller; text-align: left">
              <label v-if="!isBandera">Usuario Asignado</label>
              <label style="color: brown !important;" v-if="isBandera">Usuario Asignado</label>
            </div> 
            <input :disabled="this.modo == 5" v-model="busqueda" type="text" />
            <ul  class="autocomplete-results" v-if="usuariosFiltrados.length > 0  && this.modo != 5">
              <li class="autocomplete-result" v-for="item in usuariosFiltrados" :key="item.usuariosId" @click="seleccionarUsuario(item)">
                {{ item.usuarioNomCompleto }}
              </li>
            </ul>
          </div>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button
          >
          <md-button
            class="md-primary"
            @click.native="postNow()"
            >Guardar</md-button
          >
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import Fuse from "fuse.js";
import servTramite from "@/Servicios/Tramites/tramites.js";
import servCorreo from "@/Servicios/Correo/Correo";
import Swal from "sweetalert2";
//import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
export default {
  props: {
    tableData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    Pagination,
    Modal,
  },
  computed: {
    queriedData() {
      let result = this.tableData.map(item => ({ ...item, selected: false }));
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    hasSelection() {
      return this.queriedData.some(item => item.selected);
    },
    usuariosFiltrados() {
    //console.log("Búsqueda actual:", this.busqueda);
    if (this.busqueda.trim() !== "" && this.fuseSearch) {
      const resultados = this.fuseSearch.search(this.busqueda.trim());
      //console.log("Resultados del filtro:", resultados);
      return resultados.map((resultado) => resultado.item);
    } else {
      return [];
    }
    },
  },
  data() {
    return {
      currentSort: "date",
      currentSortOrder: "desc",
      classicModal : false,
      TramiteId : 0,
      NumeroTramite: 0,
      busqueda: '',
      isBandera : false,
      modo: 0,
      UsuarioId: 0,
      CorreoId : 0,
      lista: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      agrupacion: false,
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      fuseSearch2: null,
      tramites: [],
      tramitesLista: [],
      condicion: false,
      tramite : {
        id : 0,
        numeroTramite : 0,
        fecha : "",
        representanteCorreo: "",
        correoId: 0,
      }
    };
  },
  methods: {
    //Funcion para obtener todos los correos 
    getDatos() {
      servCorreo.all().then((res) => {        
        this.tableData = res.data;
      });
    },
    //Metodo para Seleccionar usuario de la lista 
    seleccionarUsuario(item) {
      if (item && item.usuarioNomCompleto) {
        this.busqueda = item.usuarioNomCompleto;
        this.usuariosFiltrados.length = 0;
        this.UsuarioId = item.usuariosId;
      }
    },
    // Obtener los usuarios que son representantes o asesores 
    getUsers() {
    servTramite.getUsuario(3)
    .then((r) => {
      if (r && r.data && Array.isArray(r.data)) {
        this.lista = r.data;
        this.fuseSearch = new Fuse(this.lista, {
          keys: ["usuarioNomCompleto"], // Asegúrate de que "usuarioNomCompleto" existe en los objetos
          threshold: 0.3,
        });
      } else {
        console.error("Respuesta inválida del servicio:", r);
      }
    })
    .catch((error) => {
      console.error("Error al cargar usuarios:", error);
    });
    },
    getClass: ({ id }) => ({
      'md-primary': id === 2,
      'md-accent': id === 3
    }),
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return b[sortBy].localeCompare(a[sortBy]);
        }
        return a[sortBy].localeCompare(b[sortBy]);
      });
    },
    onSelect(itemSelected) {
      if (itemSelected) {
        console.log("emailselected", itemSelected);
        this.$emit('emailSelected', itemSelected);
      }
    },
  onCheckboxChange(item) {
    item.selected = !item.selected;
  },
  //Funcion para quitar la agrupacion de los correos 
     quitarAgrupacion(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        buttonsStyling: false,
      });
      if (id > 0) {
              swalWithBootstrapButtons.fire({
        title: "!Quitar Agrupamiento!",
        text: `¿Deseas quitar el agrupamiento del siguiente correo: ${id}?`,
        type: "success",
        showCancelButton: true,
        confirmButtonClass: "md-button md-success",
        cancelButtonClass: "md-button md-danger",
        confirmButtonText: "Sí, quitar!",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
      })
      .then((r) => {
        if (r.value) {
          servTramite
            .put(id)
            .then((response) => {
              this.ProcesamosResponse(response.data);
            })
            .catch(function (e) {
              console.error("Error:", e); 
            });
        }
      })
      .catch(function (e) {
        console.error("Error en la confirmación:", e);
      });
      }
    },
  classicModalHide() {
      this.Clear();
      this.classicModal = false;
    },
  Clear(){
    this.tramites = [];
    this.TramiteId = 0;
    this.NumeroTramite = 0;
    this.UsuarioId = 0;
    this.busqueda = "";
  }, 
  postNow() {
    this.classicModal = true;
    // Actualizar las propiedades de cada elemento en this.tramites
    this.tramites = this.tramites.map((correo) => ({
        TramiteId: 0,
        NumeroTramite: this.NumeroTramite,
        UsuarioId: this.UsuarioId,
        CorreoId: correo.CorreoId, // Preservar la propiedad original
    }));
    // Imprimir los trámites modificados
    servTramite.post(this.tramites).then((r) => {
       this.ProcesamosResponse(r.data);
    });
    console.log('Trámites 2.0:', this.tramites);
  }, 
  agruparTramites() {
    // Limpiar la lista de tramites antes de agregar nuevos
    this.tramites = [];
    this.classicModal = true;
    // Filtrar los correos seleccionados (con checkbox marcado)
    const seleccionados = this.queriedData.filter(item => item.selected);
    seleccionados.forEach((correo) => {
      const nuevoTramite = {
        TramiteId: 0, 
        NumeroTramite: this.NumeroTramite,  
        UsuarioId: this.UsuarioId, 
        CorreoId: correo.id, 
      };
      // Agregar el tramite a la lista de tramites
      this.tramites.push(nuevoTramite);
    });

    // Imprimir o usar los tramites según lo necesites
    console.log('Trámites agrupados:', this.tramites);
   },
   ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
          console.log("Mensaje de éxito recibido:", e.mensaje);
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.getDatos();
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
  },
  watch: {
    searchQuery(value) {
      if (!this.fuseSearch2) {
        this.fuseSearch2 = new Fuse(this.tableData, {
          keys: ["nombreRemitente", "direccionRemitente", "asunto"],
          threshold: 0.3,
        });
      }
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch2.search(value).map(r => r.item);
      }
      this.searchedData = result;
    },
    tableData: {
      immediate: true,
      handler(value) {
        this.fuseSearch2 = new Fuse(value, {
          keys: ["nombreRemitente", "direccionRemitente", "asunto"],
          threshold: 0.3,
        });
      }
    }
  },
  mounted(){
    this.getUsers();
    this.getDatos();
  }
};
</script>
<style scoped>
.multi-line-cell {
  white-space: pre-line;
  /* Permite saltos de línea */
  word-break: break-word;
  /* Permite que las palabras se dividan si son demasiado largas */
}
.large-centered-checkbox {
  transform: scale(1.7); /* Aumenta el tamaño del checkbox */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
}
.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete input {
  width: 100%;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  border-top: none;
  border-right: none;
  border-left: none;
}

.autocomplete input:focus {
  border-color: #007bff;
  outline: none;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style: none;
}

.autocomplete-result {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.autocomplete-result:hover {
  background-color: #f1f1f1;
}

.autocomplete-result:active {
  background-color: #007bff;
  color: #fff;
}
.btn-red {
  background-color: #aa0d0d; 
  color: white;            
  font-size: 12px;        
  padding: 10px 20px;      
  border: none;          
  cursor: pointer;         
  border-radius: 4px;      
  text-transform: uppercase; 
}
</style>