<template>
  <div class="content">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Listado de Solicitudes</h4>
        </md-card-header>

        <md-card-content>
          <md-checkbox v-model="esRenovacion">Es Renovación</md-checkbox>
          <md-table
            :value="queriedData"
            class="paginated-table table-striped table-hover"
            table-header-color="green"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input
                  type="date"
                  class="mb-3"
                  clearable
                  style="width: 130px"
                  placeholder="Fecha Inicio"
                  v-model="selectedInicio"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-input
                  type="date"
                  class="mb-3"
                  clearable
                  style="width: 130px"
                  placeholder="Fecha Fin"
                  v-model="selectedFin"
                >
                </md-input>
              </md-field>
              <md-field>
                <label for="proceso">Tipo de proceso</label>
                <md-select v-model="tipoProc" name="proceso">
                  <md-option
                    v-for="tipos in proceso"
                    :key="tipos.tipoProcesoId"
                    :value="tipos.tipoProcesoId"
                    >{{ tipos.descripcion }}</md-option
                  >
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar registro"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
                
              
              <md-button class="md-info" @click="AplicarFiltro()"
                >Filtrar</md-button
              >
              <md-field>
                <md-input
                  type="numeric"
                  class="mb-3"
                  clearable
                  style="width: 100px"
                  placeholder="No. Solicitud"
                  v-model="solicitudid"
                  v-on:keyup.enter="Buscamos()"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="Buscamos()"
                  >Buscar</md-button
                >
              </md-field>
              <md-button @click="excel">Generar Excel</md-button>
              <md-button v-if="this.usuario.areaId != 2 || this.usuario.areaId != 3" class="md-primary" @click="nuevaSolicitud"
                >Nueva Solicitud</md-button
              >
            </md-table-toolbar>

            <md-progress-bar
              class="md-warning"
              md-mode="buffer"
              v-if="isLoader == true"
            ></md-progress-bar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="ID">{{
                item.solicitudId
              }}</md-table-cell>
              <md-table-cell md-label="Tipo Poliza">{{
                item.solicitudTipoPoliza
              }}</md-table-cell>
              <md-table-cell v-if="item.solicitudTipoRegimen == 1" md-label="Arrendador"
                >{{ item.solicitudApePaternoProp }}
                {{ item.solicitudApeMaternoProp }}
                {{ item.solicitudNombreProp }}</md-table-cell
              >
              <md-table-cell v-if="item.solicitudTipoRegimen == 2" md-label="Arrendador"
                >{{ item.solicitudApePaternoLegal }}
                {{ item.solicitudApeMaternoLegal }}
                {{ item.solicitudNombreLegal }}</md-table-cell
              >
              <md-table-cell md-label="Dirección Inmueble">{{
                item.solicitudUbicacionArrendado
              }}</md-table-cell>
              <md-table-cell md-label="Fecha Solicitud">{{
                item.solicitudFechaSolicitud
              }}</md-table-cell>
              <md-table-cell md-label="Captura">{{
                item.creadorNombreCompleto
              }}</md-table-cell>
              <md-table-cell md-label="Representante">{{
                item.representanteNombreCompleto
              }}</md-table-cell>
              <md-table-cell md-label="Asesor">{{
                item.asesorNombreCompleto
              }}</md-table-cell>
              <md-table-cell md-label="Estatus">{{
                item.solicitudEstatus
              }}</md-table-cell>
              <md-table-cell md-label="Reno" v-if="item.isRenovacion == true"
                ><md-icon class="md-primary"
                  >check_circle</md-icon
                ></md-table-cell
              >
              <md-table-cell md-label="Reno" v-else></md-table-cell>
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-info md-simple"
                  @click.native="Escenario(1, item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-success md-simple"
                  @click.native="Escenario(2, item)"
                >
                  <md-icon>sync</md-icon>
                </md-button>
                <md-button class="md-just-icon md-info md-simple"
                @click="flujoSolicitud(item)">
                <md-icon>people</md-icon>
              
              </md-button>
                <md-button
                  class="md-just-icon md-primary md-simple"
                  @click.native="Escenario(3, item)"
                >
                  <md-icon>thumbs_up_down</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <caption>Descripción de los datos en el pie de la tabla</caption>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} de {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/components";
import tipoProceso from "@/Servicios/Solicitudes/tipoProceso.js";
import Fuse from "fuse.js";
import excel from "./js/GeneraExcel.js";
import Solicitudes from "@/Servicios/Solicitudes/solicitudes.js";
import store from "@/Store/index";
import Swal from "sweetalert2";
import UsuarioSol from "@/Servicios/Operacion/estatus.js"
import { Icon } from "element-ui";
export default {
  name: "Solicitudes",
  components: {
    Pagination,
  },
  data() {
    return {
      selected: [],
      tableData: [],
      proceso: "",
      selectedInicio: "",
      selectedFin: "",
      tipoProc: 0,
      busca: "",
      esRenovacion: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      footerTable: [
        "ID",
        "Tipo Poliza",
        "Arrendador",
        "Dirección Inmueble",
        "Fecha Solicitud",
        "Captura",
        "Representante",
        "Asesor",
        "Estatus",
        "Reno",
        "Acciones",
      ],
      isLoader: true,
      searchedData: [],
      searchQuery: "",
      fuseSearch: null,
      solicitudid: null,
    };
  },
  methods: {
    Escenario(opc, item) {
      switch (opc) {
        case 1:
          if (item.solicitudId > 0) {
            Solicitudes.GetIndicesSolicitud(item.solicitudId)
            .then((r) => {
              store.state.General.SolicitudId = item.solicitudId;
              store.state.General.FisicaMoralId = r.data.fisicaMoralId;
              store.state.General.PolizaId = r.data.polizaId;
              store.state.General.SolucionId = r.data.solucionId;
              store.state.Modo = 1;
              this.$router.push("/operacion");
            }).catch((e) => {
              console.log("error en el servicio buscar solicitud", e);
            });
          }
          break;
        case 2:
          if (item.solicitudId > 0) {
            const swalWithBootstrapButtons = Swal.mixin({
           customClass: {
            confirmButton: "md-button md-success",
            cancelButton: "md-button md-danger",
            },
           buttonsStyling: false,
           });
           swalWithBootstrapButtons.fire({
            title: "!Duplicar!",
            text: `¿Deseas duplicar la siguiente solicitud?: ${item.solicitudId} ?`,
            type: "success",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Si, Duplicar!",
            cancelButtonText: "Cancelar",
            buttonsStyling: false,
          })
            .then((r) => {
              if (r.value) {
                Solicitudes
                  .reciclarSolicitud(item.solicitudId)
                  .then((response) => {
                    swalWithBootstrapButtons.fire({
                      title: "Duplicado!",
                      text: `Se ha duplicado la solicitud ${item.solicitudId}`,
                      type: "success",
                      confirmButtonClass: "md-button md-success",
                      cancelButtonText: "Cancelar",
                      buttonsStyling: false,
                    });
                    this.ProcesamosResponse(response.data);
                  })
                  .catch(function (e) {
                    mensaje.Error("usuario-crud-sercategorias-delete", e);
                  });
              }
            })
            .catch(function (e) {
              mensaje.Error("usuario-crud-sercategorias-delete", e);
            });
            }
          break;
          case 3:
          const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: "md-button md-success",
              cancelButton: "md-button md-danger",
            },
            buttonsStyling: false,
            });
          if (item.solicitudId > 0) {
            swalWithBootstrapButtons.fire({
            title: "Cambiar de Estatus",
            text: `¿Deseas pasar al siguiente estatus del proceso?`,
            type: "success",
            showCancelButton: true,
            confirmButtonClass: "md-button md-success",
            cancelButtonClass: "md-button md-danger",
            confirmButtonText: "Si, Pasar el siguiente Estatus!",
            cancelButtonText: "Cancelar",
            buttonsStyling: false,
          })
          .then((r) => {
              if (r.value) {
                UsuarioSol
                  .postEstatusSiguiente(item.solicitudId, this.usuario.usuariosId)
                  .then((response) => {          
                    this.ProcesamosResponse(response.data);
                  })
                  .catch(function (e) {
                    mensaje.Error("usuario-crud-sercategorias-delete", e);
                  });
              }
            })
            .catch(function (e) {
              mensaje.Error("usuario-crud-sercategorias-delete", e);
            });
        }
        break;
      } 
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 3: //success       
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.ObtieneDatos();
            break;
          case 5: //success       
            m.mensaje = `La nueva solicitud es la: ${e.mensaje}`;
            m.tipo = "success";
            this.notifyVue(m);
            this.ObtieneDatos();
            break;
          case 1: //success       
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.ObtieneDatos();
            break;
        }
      });
    }, 
    Buscamos() {
      //mensaje de busqueda
      let m = {
        mensaje: "",
        tipo: "",
      };
      if (this.solicitudid == 0) {
        m.mensaje = "El Id de la solicitud debe de ser diferente de 0 (cero)";
        m.tipo = "danger";
        this.notifyVue(m);
      } else {
        Solicitudes.BuscaSolicitud(this.solicitudid, this.usuario.usuariosId)
          .then((r) => {
            this.tableData = [];
            this.tableData = r.data;
            // Fuse search initialization.
            this.fuseSearch = new Fuse(this.tableData, {
              keys: [
                "Estatus",
                "solicitudFechaSolicitud",
                "isRenovacion",
                "asesorNombreCompleto",
              ],
              threshold: 0.3,
            });
          })
          .catch((e) =>
            console.log("error en el servicio buscar solicitud", e)
          );
      }
    },
    obtieneTipoProceso() {
      tipoProceso.tiposProceso().then((data) => {
        this.proceso = data.data;
      });
    },
    ObtieneDatos() {
      this.isLoader = true;      
      let data = {
        TipoProcesoId: this.tipoProc,
        fechaInicio: this.selectedInicio,
        fechaFin: this.selectedFin,
        isRenovacion: this.esRenovacion,
        usuarioId: this.usuario.usuariosId,
      };

      Solicitudes.GetSolicitudes(data)
        .then((element) => {
          //console.debug("grid-solicitudes", element.data);
          this.tableData = element.data;
          this.isLoader = false;
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: [
              "Estatus",
              "solicitudFechaSolicitud",
              "isRenovacion",
              "asesorNombreCompleto",
            ],
            threshold: 0.3,
          });
        })
        .catch((e) => {
          console.log(e);
          //mensaje.Error("Solicitudes-getSolicitudes", e);
          this.isLoader = false;
        });
    },
    excel() {
      excel.GeneraExcel(this.tableData);
    },
    nuevaSolicitud() {
      this.$router.push({ name: "createUpdateSolicitud" });
      store.state.General.SolicitudId = 0;
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);
      this.selectedInicio = firstDay.toISOString();
      this.selectedFin = lastDay.toISOString();
    },
    AplicarFiltro() {
      this.ObtieneDatos();
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    flujoSolicitud(item){
      //abre el Estatus
      this.$router.push({name: "FlujoSolicitud"});
      store.state.General.SolicitudId = item.solicitudId;
      store.state.Modo = 5;
    },
  },
  created() {
    this.SetFechas();
    this.obtieneTipoProceso();
    this.ObtieneDatos();
  },
  mounted() {},
  watch: {
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((r) => r.item);
    },
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    usuario() {
      return store.state.usuario;
    },

  },
};
</script>

<style>
/* @import './assets/css/yourstyles.css'; */
</style>
