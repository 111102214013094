<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Generar contrato</h4>
        </md-card-header>
        <md-card-content>
          <div>
            <!-- Sección de generación de contrato (izquierda) -->
            <div>
              <md-switch v-model="aplica" id="aplica" name="aplica" class="md-primary
                  md-size-24">Aplica análisis de IA</md-switch>
              <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort" class="paginated-table table-striped table-hover">
                <md-table-toolbar>
                  <md-field>
                    <label for="pages">Por página</label>
                    <md-select v-model="pagination.perPage" name="pages">
                      <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>

                  <md-field>
                    <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                      v-model="searchQuery"></md-input>
                  </md-field>
                </md-table-toolbar>
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell md-label="Tipo">{{ item.documentoPlantillaTipo }}</md-table-cell>
                  <md-table-cell md-label="ID">{{ item.documentoPlantillaId }}</md-table-cell>
                  <md-table-cell md-label="Nombre">{{ item.documentoPlantillaNombre }}</md-table-cell>
                  <md-table-cell md-label="Acciones">
                    <button :disbaled="visible" style="
                    font-size: 16px;
                    padding: 8px 16px;
                    background-color: rgb(170, 13, 13);
                    color: white;
                    border: none;
                    cursor: pointer;
                  " class="accion-text" @click="generarContrato(item)" :disabled="permisos()">
                      <md-progress-spinner v-if="visible" class="md-accent" md-mode="indeterminate"
                        :md-diameter="20"></md-progress-spinner>
                      <span v-else>Generar</span>
                    </button>
                  </md-table-cell>
                </md-table-row>
              </md-table>
            </div>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Observaciones</h4>
        </md-card-header>
        <md-card-content>
          <div style="display: flex;">
            <!-- Sección de generación de contrato (izquierda) -->
            <div style="flex: 1;">
              <!-- En tu template, reemplaza la sección de la tabla de observaciones actual con esto: -->
              <md-table>
                <md-table-row>
                  <md-table-cell><strong>Id Plantilla</strong></md-table-cell>
                  <md-table-cell><strong>Nombre</strong></md-table-cell>
                  <md-table-cell><strong>Tipo</strong></md-table-cell>
                  <md-table-cell><strong>Fecha</strong></md-table-cell>
                  <md-table-cell><strong>Acciones</strong></md-table-cell>
                </md-table-row>

                <template v-for="item in tableObservaciones">
                  <!-- Fila principal -->
                  <md-table-row :key="item.observacionesId">
                    <md-table-cell>{{ item.documento.documentoPlantillaNombre }}</md-table-cell>
                    <md-table-cell>{{ item.documento.documentoPlantillaTipo }}</md-table-cell>
                    <md-table-cell>{{ item.documentoId }}</md-table-cell>
                    <md-table-cell>{{ item.fecha }}</md-table-cell>
                    <md-table-cell>
                      <md-button class="md-icon-button md-primary" @click="toggleObservacion(item.observacionesId)">
                        <md-icon>{{ expandedRow === item.observacionesId ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                          }}</md-icon>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>

                  <!-- Fila expandible -->
                  <md-table-row v-if="expandedRow === item.observacionesId" :key="`${item.observacionesId}-details`">
                    <md-table-cell colspan="5">
                      <div class="observaciones-content">
                        <template v-if="parsedObservacion(item.observacion)">
                          <!-- Errores Ortográficos -->
                          <div v-if="parsedObservacion(item.observacion).ErroresOrtograficos?.length"
                            class="observacion-section">
                            <h4 class="md-title">Errores Ortográficos</h4>
                            <ul class="md-list">
                              <li v-for="(error, index) in parsedObservacion(item.observacion).ErroresOrtograficos"
                                :key="'ort-' + index" class="md-list-item">
                                {{ error }}
                              </li>
                            </ul>
                          </div>

                          <!-- Errores Gramaticales -->
                          <div v-if="parsedObservacion(item.observacion).ErroresGramaticales?.length"
                            class="observacion-section">
                            <h4 class="md-title">Errores Gramaticales</h4>
                            <ul class="md-list">
                              <li v-for="(error, index) in parsedObservacion(item.observacion).ErroresGramaticales"
                                :key="'gram-' + index" class="md-list-item">
                                {{ error }}
                              </li>
                            </ul>
                          </div>

                          <!-- Problemas de Redacción -->
                          <div v-if="parsedObservacion(item.observacion).ProblemasRedaccion?.length"
                            class="observacion-section">
                            <h4 class="md-title">Problemas de Redacción</h4>
                            <ul class="md-list">
                              <li v-for="(problema, index) in parsedObservacion(item.observacion).ProblemasRedaccion"
                                :key="'red-' + index" class="md-list-item">
                                {{ problema }}
                              </li>
                            </ul>
                          </div>

                          <!-- Ambigüedades -->
                          <div v-if="parsedObservacion(item.observacion).Ambiguedades?.length"
                            class="observacion-section">
                            <h4 class="md-title">Ambigüedades</h4>
                            <ul class="md-list">
                              <li v-for="(ambiguedad, index) in parsedObservacion(item.observacion).Ambiguedades"
                                :key="'amb-' + index" class="md-list-item">
                                {{ ambiguedad }}
                              </li>
                            </ul>
                          </div>

                          <!-- Sugerencias de Mejora -->
                          <div v-if="parsedObservacion(item.observacion).SugerenciasMejora?.length"
                            class="observacion-section">
                            <h4 class="md-title">Sugerencias de Mejora</h4>
                            <ul class="md-list">
                              <li v-for="(sugerencia, index) in parsedObservacion(item.observacion).SugerenciasMejora"
                                :key="'sug-' + index" class="md-list-item">
                                {{ sugerencia }}
                              </li>
                            </ul>
                          </div>
                        </template>
                      </div>
                    </md-table-cell>
                  </md-table-row>
                </template>
              </md-table>
            </div>

          </div>
        </md-card-content>
      </md-card>

      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <h3 class="title">Jurisdiccion</h3>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-80 md-medium-size-100">
                <md-field>
                  <label v-if="!isBandera">*Jurisdiccion</label>
                  <label style="color: brown !important;" v-if="isBandera">*Jurisdiccion</label>
                  <md-input v-model="jurisdiccion"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20 md-medium-size-100 md-layout-item--right">
                <md-button v-if="this.visible == false" class="md-primary" :disabled="permisos()"
                  @click.native="Jurisdiccion(general.FisicaMoralId, jurisdiccion, {})">Guardar</md-button>
                <md-button v-if="this.visible == true" class="md-primary" disabled>
                  <div class="loader"></div>
                </md-button>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import { Pagination } from "@/components";
import servDoc from "@/Servicios/Operacion/documentoPlantilla.js";
import Fuse from "fuse.js";
import store from "@/Store/index";
import servJur from "@/Servicios/Solicitudes/solicitudes.js";

export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modoPantalla() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      currentSort: "",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      tableData: [],
      tableObservaciones: [],
      contentObservacion: "",
      searchQuery: [],
      searchedData: "",
      jurisdiccion: "",
      fuseSearch: null,
      isDisabled: false,
      isBandera: false,
      visible: false,
      files: [],
      expandedRow: null,
      aplica: false,
    };
  },
  methods: {
    // FUNCION PARA VALIDAR SI EL USUARIO PUEDE EDITAR O MOVER ALGO DEL LA SOLICIUD
    permisos() {
      let condicion = false;
      if (this.usuario.areaId == 7) {
        condicion = true;
      }
      return condicion;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    getAllDoc() {
      servDoc.all().then((r) => {
        this.tableData = r.data;
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["documentoPlantillaNombre", "documentoPlantillaTipo"],
          threshold: 0.3,
        });
      });
    },
    Jurisdiccion(id, jurisdiccion, data) {
      if (!jurisdiccion) {
        this.isBandera = true;  // Cambia el color del label
        let m = {
          mensaje: "El campo Jurisdicción es obligatorio.",
          tipo: "danger"
        };
        this.notifyVue(m);  // Muestra el mensaje de error
        return;  // No continúa si el campo está vacío
      }
      this.visible = true; // Activa la animación de cargando
      servJur.jurisdiccion(id, jurisdiccion, data)
        .then(response => {
          this.ProcesamosResponse(response.data); // Procesa la respuesta del servidor
        })
        .catch(error => {
          console.error('Error al hacer la solicitud:', error);

          // Notificación de error
          let m = {
            mensaje: 'Ocurrió un error en la solicitud.',
            tipo: 'danger'
          };
          this.notifyVue(m); // Notifica al usuario del error
        })
        .finally(() => {
          this.visible = false; // Siempre desactiva la animación de cargando
        });
    },
    notifyVue(Mensajes) {
      this.$notify({
        message: Mensajes.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: Mensajes.tipo,
      });
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.isBandera = true;
            this.visible = false;
            this.notifyVue(m);
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.visible = false;
            this.notifyVue(m);
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.jurisdiccion = "";
            this.visible = false;
            this.isBandera = false;
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    async generarContrato(item) {
      try {
        let response;
        this.visible = true;
        if (this.aplica) {
          response = await servDoc.getContratoIA(
            this.general.FisicaMoralId,
            item.documentoPlantillaId,
            this.usuario.usuariosId
          );
        } else {
          response = await servDoc.getContrato(
            this.general.FisicaMoralId,
            item.documentoPlantillaId,
            this.usuario.usuariosId
          );
        }
        // Process file
        const byteCharacters = atob(response.data.fileContents);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: response.data.contentType });

        // Download file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        // Handle observations
        if (response.data.observaciones) {
          this.getObservaciones(this.general.FisicaMoralId);
          this.$notify({
            message: "La IA identificó observaciones. Favor de atenderlas",
            type: "info"
          });
        } else {
          this.$notify({
            message: "No se identifican observaciones por parte de la IA",
            type: "success"
          });
        }
      } catch (error) {
        console.error("Error:", error);
        this.$notify({
          message: `Error: ${error.message}`,
          type: "error"
        });
      } finally {
        this.visible = false;
      }
    },
    mostrarObservaciones(observaciones) {
      if (observaciones) {
        this.getObservaciones(this.general.FisicaMoralId);
        this.$notifyVue("info", "La IA identifico observaciones favor de atenderlas")
      } else {
        this.$notifyVue("success", "No se identifican observaciones por parte de la IA")
      }

      // Aquí puedes implementar la lógica para mostrar las observaciones en la interfaz de usuario
      // Por ejemplo, podrías abrir un modal o actualizar una sección de la página con esta información
    },
    getObservaciones(id) {
      servDoc.getObservaciones(id)
        .then((r) => {
          this.tableObservaciones = r.data;
        });
    },
    verObservacion(item) {
      this.contentObservacion = item;
    },
    toggleObservacion(id) {
      this.expandedRow = this.expandedRow === id ? null : id;
    },
    parsedObservacion(observacion) {
      try {
        return typeof observacion === 'string' ? JSON.parse(observacion) : observacion;
      } catch (e) {
        console.error('Error parsing observacion:', e);
        return null;
      }
    }
  },
  created() {
    this.getAllDoc();
    this.getObservaciones(this.general.FisicaMoralId);
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((r) => r.item);
    },
  },
};
</script>
<style lang="css" scoped>
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4);
}

.loader:before {
  content: "Guardando....."
}

.file-preview-container {
  width: 100%;
  /* Ajusta el ancho según sea necesario */
  max-height: 600px;
  height: 400px;
  /* Aumenta la altura máxima */
  padding: 10px;
  border: 1px solid #0a0a0a;
  border-radius: 5px;
}

/* Ajustar el tamaño del nombre del archivo */
.file-name {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Aumentar el tamaño de los videos */
.video-element {
  width: 100%;
  /* Ocupa todo el ancho del contenedor */
  max-height: 500px;
  /* Aumentar la altura máxima del video */
  margin-bottom: 10px;
}

.doc-preview {
  width: 100%;
  height: 500px;
  /* Aumentar la altura de la vista previa del documento */
  margin-bottom: 10px;
  border: none;
}

.observaciones-content {
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.observacion-section {
  margin-bottom: 24px;
}

.observacion-section:last-child {
  margin-bottom: 0;
}

.observacion-section h4 {
  color: #333;
  margin-bottom: 8px;
  font-size: 1.1em;
}

.md-list {
  list-style-type: none;
  padding-left: 16px;
}

.md-list-item {
  margin-bottom: 8px;
  color: #666;
  line-height: 1.4;
}

.md-list-item:before {
  content: "•";
  color: #666;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
