<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Estatus de la póliza</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-toolbar>
              <md-field>
                <label>Id Póliza</label>
                <md-input
                  type="numeric"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Dedes"
                  v-model="polizaId"
                >
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-default" @click="all()">Buscar</md-button>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id">{{
                item.usuarioPolizaId
              }}</md-table-cell>
              <md-table-cell md-label="Usuario">{{
                item.usuarios.usuarioNomCompleto
              }}</md-table-cell>
              <md-table-cell md-label="Estatus">{{
                item.tipoProcesoPo.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Fecha">{{ item.fecha }}</md-table-cell>
              <md-table-cell md-label="Motivo">{{
                item.observacion
              }}</md-table-cell>
            </md-table-row>

          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <slot name="footer">
            <div>
              <!---
              <md-button
                class="btn-previous"
                >
                Cancelar
              </md-button>
              -->
            </div>

            <div>
              <md-button v-if="usuario.areaId != 7"
                class="btn-next md-success"
                @click.native="ModalPersonalizado(2)">
                Cambiar proceso
              </md-button>
            </div>
          </slot>
        </md-card-actions>
      </md-card>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ datosModal.titulo }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide">
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
            <md-field>
            <label for="tipoProcesoPoId">(Seleccionar estatus)</label>
            <md-select v-model="tipoProcesoPoId">
              <md-option
                v-for="e in dataEstatus"
                :key="e.tipoProcesoPoId"
                :value="e.tipoProcesoPoId"
                >{{ e.descripcion }}</md-option>
            </md-select>
          </md-field>
          <md-field>
            <label>Motivo </label>
            <md-input v-model="observacion"></md-input>
            <span class="md-helper-text">Motivo de Cancelación</span>
          </md-field>
        </template>
        <template slot="footer">
          <md-button class="md-danger md-simple" @click="classicModalHide"
            >Cerrar</md-button >
          <md-button class="md-primary"
            @click.native="create(datosModal.evento)"
            :disabled="isActivo"
            >{{ datosModal.boton }}</md-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>  
import { Modal } from "@/components";
import servEstatusPoliza from "../../../Servicios/Polizas/estatuspoliza";
import servTipoProcesoPoliza from "../../../Servicios/Administracion/TipoProcesoPo";
import store from "../../../Store/index";
import mensaje from "../../../Servicios/ManjeadorErrores/Mensajes";

export default {
  computed: {
    General() {
      return store.state.General;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      tableData: [],
      polizaId: 0,
      classicModal: false,
      datosModal: {
        titulo: "Crear un concepto",
        boton: "Confirmar",
        evento: 0,
      },
      observacion: null,
      dataEstatus: [],
      tipoProcesoPoId: 0,
      usuarioPolizaId: 0,
      Mensajes: {
        tipo: null,
        mensaje: null,
        accion: false,
      },
      isActivo: false,
    };
  },
  methods: {
    all() {
      if (this.polizaId > 0) {
        store.state.General.Poliza = this.polizaId;
        servEstatusPoliza
          .GetEstatusPoliza(this.polizaId)
          .then((r) => {
            console.log("r: ",r.data);
            this.tableData = r.data;
          })
          .catch((e) => {
            console.log("e: ",e);
          });
      }
    },
    classicModalHide() {
      //this.Clear();
      this.classicModal = false;
      this.isActivo = false;
    },
    ModalPersonalizado(exp) {
      switch (exp) {
        case 1: //Insertar
          this.classicModal = true;
          this.datosModal.titulo ="¿Estás seguro de cambiar el estatus de la Solicitud?";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 1;
          break;
        case 2: //Cancelar
          this.classicModal = true;
          this.datosModal.titulo = "Estás seguro de cambiar el estatus de la Solicitud?";
          this.datosModal.boton = "Confirmar";
          this.datosModal.evento = 2;
          break;
      }
    },
    getEstatusPoliza() {
      //
      servTipoProcesoPoliza
        .area(this.usuario.areaId)
        .then((r) => {
          this.dataEstatus = r.data;
        })
        .catch((e) => {
          console.log("get-status-e",e);
        });
    },
    create(exp) {
      this.isActivo = true;
      var date = new Date();
      var dateString = date.toISOString();

      /*if (exp == 2) {
        this.tipoProcesoPoId = 2;
      }*/

      var data = {
        usuarioPolizaId: this.usuarioPolizaId,
        usuariosId: this.usuario.usuariosId,
        polizaId: this.polizaId,
        fecha: dateString,
        observacion: this.observacion,
        tipoProcesoPoId: this.tipoProcesoPoId,
        
      };
      console.debug("data: ", data );
      if (this.tipoProcesoPoId <= 0 && exp == 1) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje = "Seleccione el estatus";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
        this.isActivo = false;
      }
      if (this.observacion == null && exp == 2) {
        this.Mensajes.tipo = "danger";
        this.Mensajes.mensaje = "Favor de ingresar el motivo de cancelación";
        this.Mensajes.accion = true;
        this.notifyVue(this.Mensajes);
        this.isActivo = false;
      }
      switch (exp) {
        case 1:
        case 2:
          if (this.Mensajes.accion == false) {
            servEstatusPoliza
              .post(data)
              .then((r) => {
                //hay que cerrar el model
                this.isActivo = false;
                this.ProcesamosResponse(r.data);
                console.log("Data: ", data)
                //Aqui recuperamos los datos
              })
              .catch((e) => {
                this.isActivo = false;
                mensaje.Error("detallepoliza-crud-post", e);
              });
          }
          break;
      }
      this.isActive = false;
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            break;
          case 3: //success
            this.all();
            this.classicModalHide();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },

    Clear(){
      this.usuarioPolizaId=0;
      this.polizaId=0;
      this.fecha=null;
      this.observacion=null;
      this.tipoProcesoPoId=0;
    }
  },
  created() {
    this.getEstatusPoliza();
    this.polizaId = this.General.Poliza;
    this.all();
  },
};
</script>

<style scoped>