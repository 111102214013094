<template>
  <div class="flex h-screen">
    <div class="flex-1">
      <!-- <div v-if="showWelcome" class="welcome-container">
        <md-card class="welcome-card">
          <div class="text-center">
            <h1 class="welcome-text">{{ displayText }}</h1>

            <div v-if="!isTyping" class="search-container">
              <div class="relative">
                <input type="text" placeholder="¿Qué necesitas hacer hoy?" class="search-input" v-model="searchQuery"
                  @keyup.enter="onWelcomeCompleted" />
              </div>
            </div>
          </div>
        </md-card>
      </div> -->

      <!-- <div v-else> -->
        <div class="iframe-container">
          <div class="md-layout">
            <div class="md-layout-item md-size-40">
              <md-field>
                <label> Fecha Inicio</label>
                <md-input v-model="fechainicio" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-40">
              <md-field>
                <label>Fecha Fin</label>
                <md-input v-model="fechafin" type="date"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-size-20">
              <md-button class="md-primary" @click.native="Filtramos()">Filtrar</md-button>
            </div>
          </div>
          <iframe v-if="iframeUrl" :src="iframeUrl" frameborder="0" width="100%" height="100vh"></iframe>
        <!-- </div> -->

        <!-- <beautiful-chat :participants="participants" :titleImageUrl="titleImageUrl" :onMessageWasSent="onMessageWasSent"
          :messageList="messageList" :newMessagesCount="newMessagesCount" :isOpen="isChatOpen" :close="closeChat"
          :open="openChat" :showEmoji="true" :showFile="false" :showTypingIndicator="showTypingIndicator"
          :colors="chatColors" :alwaysScrollToBottom="true" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Chat } from 'vue-beautiful-chat'
import store from "@/Store";
import servMetabase from "@/Servicios/Dashboard/dashboard";

export default {
  name: "Metabase-Representacion",
  components: {
    'beautiful-chat': Chat
  },
  data() {
    return {
      iframeUrl: "",
      showWelcome: false,
      isTyping: true,
      displayText: "",
      welcomeText: "Es el momento de que solo te enfoques a vender y toda la gestión o administración de tu operación la hagamos en conjunto Póliza Jurídica IA",
      searchQuery: "",
      isChatOpen: false,
      participants: [
        {
          id: 'bot',
          name: 'Póliza Jurídica IA',
          imageUrl: null
        }
      ],
      messageList: [],
      chatColors: {
        header: {
          bg: '#AF2327',
          text: '#ffffff'
        },
        launcher: {
          bg: '#AF2327'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#AF2327',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#9A9A9A',
          text: '#ffffff'
        }
      },
      showTypingIndicator: false,
      fechainicio: '',
      fechafin: ''
    };
  },
  computed: {
    usuario() {
      return store.state.usuario;
    }
  },
  mounted() {
    // this.typeWriter();   
    this.SetFechas(); 
    this.getToken();
  },
  methods: {
    toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    typeWriter() {
      if (!this.welcomeText) {
        console.error('Welcome text is undefined');
        return;
      }

      let index = 0;
      const interval = setInterval(() => {
        if (index < this.welcomeText.length) {
          this.displayText += this.welcomeText.charAt(index);
          index++;
        } else {
          this.isTyping = false;
          clearInterval(interval);
        }
      }, 50);
    },
    async getToken() {
      try {
        const response = await servMetabase.Metabase(
          this.usuario.usuariosId,
          this.usuario.representacionId,
          this.fechainicio,
          this.fechafin
        );
        this.iframeUrl = response.data.iframeUrl;
      } catch (error) {
        console.error("Error al obtener el token:", error);
      }
    },
    onWelcomeCompleted() {
      this.showWelcome = false;
      this.$notifyVue('info', 'Bienvenid@ ' + this.usuario.usuarioNombre);
      this.getToken();
      this.openChat();
      // Add initial bot message
      this.messageList.push({
        type: 'text',
        author: 'bot',
        data: { text: '¡Hola! ¿En qué puedo ayudarte hoy?' }
      });
    },
    onMessageWasSent(message) {
      this.messageList.push(message);
    },
    openChat() {
      this.isChatOpen = true;
    },
    closeChat() {
      this.isChatOpen = false;
    },
    SetFechas() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11 + 1, 0);
      this.fechainicio = firstDay.toISOString().split('T')[0];;
      this.fechafin = lastDay.toISOString().split('T')[0];;
    },
    Filtramos() {
      this.getToken();
    }
  }
};
</script>

<style scoped>
.transition-all {
  transition: all 0.3s ease;
}

.iframe-container {
  width: 100%;
  height: 100vh;
}

.welcome-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem;
}

.welcome-text {
  font-size: 3rem;
  font-weight: 600;
  font-style: italic;
  font-family: 'Segoe UI', sans-serif;
  background: linear-gradient(120deg,
      #AF2327 0%,
      #9A9A9A 50%,
      #AF2327 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 4s linear infinite, fadeIn 1s ease-in;
  text-align: center;
  max-width: 800px;
  line-height: 1.4;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-container {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.search-input {
  width: 100%;
  max-width: 500px;
  padding: 1rem 1.5rem;
  border-radius: 9999px;
  border: 2px solid #e2e8f0;
  background: white;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.search-input::placeholder {
  text-align: center;
  color: #a0aec0;
  font-style: italic;
}

.search-input:focus::placeholder {
  opacity: 0.5;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.welcome-card {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>