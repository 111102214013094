<!-- KanbanBoard.vue -->
<template>
  <div>
    <!-- Top Navigation -->
    <md-toolbar class="md-transparent md-elevation-0">
      <div class="md-toolbar-row">
        <div class="md-toolbar-section-start">
          <h2 class="md-title">Tablero de Control - Kanban</h2>
          <span class="md-caption">2 tasks completed today</span>
        </div>
        <div class="md-toolbar-section-end">
          <md-button class="md-dense md-raised">
            <md-icon>filter_list</md-icon>
            Filter
          </md-button>
          <md-button class="md-dense">
            <md-icon>sort</md-icon>
            Sort
          </md-button>
        </div>
      </div>
    </md-toolbar>

    <!-- Kanban Board -->
    <div class="md-layout kanban-container">
      <div v-for="(column, columnIndex) in columns" 
           :key="column.title"
           class="md-layout-item md-size-25">
        <!-- Column Card -->
        <md-card class="kanban-column">
          <md-card-header>
            <div class="md-title">{{ column.title }}</div>
            <md-button class="md-icon-button">
              <md-icon>more_vert</md-icon>
            </md-button>
          </md-card-header>

          <!-- Draggable Tasks Container -->
          <draggable
            :list="column.tasks"
            group="tasks"
            class="task-list"
            @end="onDragEnd"
          >
            <!-- Task Cards -->
            <md-card
              v-for="task in column.tasks"
              :key="task.id"
              class="task-card md-elevation-3"
            >
              <md-card-header>
                <div class="md-title">{{ task.title }}</div>
              </md-card-header>

              <!-- Task Tags -->
              <md-card-content>
                <md-chip v-if="task.tag" class="md-primary" v-text="task.tag"></md-chip>
                
                <div class="task-details">
                  <!-- Assignee and Date -->
                  <div class="assignee-date">
                    <md-avatar class="md-small">
                      <img :src="task.assignee" alt="Assignee">
                    </md-avatar>
                    <span v-if="task.date" class="date-text">{{ task.date }}</span>
                  </div>

                  <!-- Task Stats -->
                  <div class="task-stats">
                    <span v-if="task.comments" class="stat-item">
                      <md-icon class="md-size-16">comment</md-icon>
                      {{ task.comments }}
                    </span>
                    <span v-if="task.shares" class="stat-item">
                      <md-icon class="md-size-16">repeat</md-icon>
                      {{ task.shares }}
                    </span>
                    <span v-if="task.likes" class="stat-item">
                      <md-icon class="md-size-16">thumb_up</md-icon>
                      {{ task.likes }}
                    </span>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </draggable>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'KanbanBoard',
  
  components: {
    draggable
  },

  data() {
    return {
      columns: [
        {
          title: 'Renovaciones',
          tasks: [
            {
              id: 1,
              title: 'Landing page template',
              comments: 8,
              shares: 1,
              assignee: '/api/placeholder/32/32'
            },
            {
              id: 2,
              title: 'Feature image for blog',
              tag: 'Illustration',
              comments: 4,
              shares: 2,
              assignee: '/api/placeholder/32/32'
            },
            {
              id: 3,
              title: 'Support page form',
              comments: 3,
              assignee: '/api/placeholder/32/32'
            }
          ]
        },
        {
          title: 'Por Hacer',
          tasks: [
            {
              id: 4,
              title: 'New favicon',
              date: 'Dec 3',
              likes: 2,
              assignee: '/api/placeholder/32/32'
            },
            {
              id: 5,
              title: 'Welcome modal',
              comments: 2,
              shares: 2,
              date: 'Jan 16',
              assignee: '/api/placeholder/32/32'
            }
          ]
        },
        {
          title: 'En Proceso',
          tasks: [
            {
              id: 8,
              title: 'Hero illustration',
              tag: 'Illustration',
              likes: 5,
              comments: 2,
              date: 'Friday',
              assignee: '/api/placeholder/32/32'
            },
            {
              id: 9,
              title: 'Product page layout',
              comments: 4,
              shares: 2,
              date: 'Jan 16',
              assignee: '/api/placeholder/32/32'
            }
          ]
        },
        {
          title: 'Concluidas',
          tasks: [
            {
              id: 11,
              title: 'Homepage layout',
              tag: 'Web design',
              comments: 4,
              likes: 2,
              date: 'Jan 16',
              assignee: '/api/placeholder/32/32'
            },
            {
              id: 12,
              title: 'Navigation design',
              tag: 'Web design',
              comments: 3,
              date: 'Jan 10',
              assignee: '/api/placeholder/32/32'
            }
          ]
        }
      ]
    }
  },

  methods: {
    onDragEnd(evt) {
      console.log('Task moved:', evt)
    }
  }
}
</script>

<style lang="scss">
.kanban-container {
  padding: 16px;
  overflow-x: auto;
  min-height: calc(100vh - 120px);
}

.kanban-column {
  margin: 8px;
  min-height: 500px;
  background-color: #f5f5f5 !important;

  .md-title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .md-card-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.task-list {
  padding: 8px;
  min-height: 100px;
}

.task-card {
  margin-bottom: 16px;
  cursor: move;
  background-color: white !important;

  .md-title {
    font-size: 0.9rem;
    font-weight: 500;
  }

  .md-card-header {
    padding: 16px 16px 0;
  }

  .md-card-content {
    padding: 8px 16px 16px;
  }

  .task-details {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .assignee-date {
    display: flex;
    align-items: center;
    gap: 8px;

    .date-text {
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  .task-stats {
    display: flex;
    gap: 16px;

    .stat-item {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);

      .md-icon {
        font-size: 16px !important;
        min-width: 16px;
        min-height: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.md-chip {
  margin-top: 8px;
}

// Ghost class for dragging
.sortable-ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}

.sortable-drag {
  cursor: move;
}
</style>