<template>
  <div class="md-layout">
    <div class="md-layout-item md-small-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-primary">
          <div class="card-icon">
            <md-icon>mail_outline</md-icon>
          </div>
          <h4 class="title">Investigación</h4>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-size-100 md-medium-size-100">
            <div class="md-layout">
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Texto 1</label>
                  <md-textarea v-model="texto1"></md-textarea>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-medium-size-100">
                <md-field>
                  <label>Texto 2</label>
                  <md-textarea v-model="texto2"></md-textarea>
                </md-field>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="hipoteca"
                      >Hipotecas</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="controversias" 
                      >Controversias de Arrendamiento</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="procesosP"
                      >Procesos Penales</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="alertas"
                      >Alertas en Medios
                    </md-checkbox>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="ejecutivo"
                      >Ejecutivo Mercantil</md-checkbox
                    >
                  </div>
                </div>
              </div>
        </md-card-content>
        <md-card-actions md-alignment="right">
          <md-button v-if="this.visible == false" class="md-primary" :disabled="permisos()" @click.native="postNow()">GUARDAR </md-button>
          <md-button v-if="this.visible == true" class="md-primary" disabled>
            <div class="loader"></div>
          </md-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import store from "@/Store/index";
import servInv from "@/Servicios/Operacion/investigacion.js";
import mensaje from "@/Servicios/ManjeadorErrores/Mensajes";
import servFM from "@/Servicios/Operacion/fisicamolal.js";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modo() {
      return store.state.Modo;
    },
  },
  data() {
    return {
      id: 0,
      texto1: "",
      texto2: "",
      hipoteca: false,
      controversias : false,
      procesosP : false,
      alertas: false,
      ejecutivo : false,
      visible: false,
      dataFm : [],
    };
  },
  methods: {
    // FUNCION PARA VALIDAR SI EL USUARIO PUEDE EDITAR O MOVER ALGO DEL LA SOLICIUD
    permisos(){
       let condicion = false;
       if (this.usuario.areaId == 7)
       {
         condicion = true;
       }
      return condicion;
    },
    postNow() {
      this.visible = true;
      let datosRi = {
        Id: this.id,
        Texto1: this.texto1 ? this.texto1 : "",
        Texto2: this.texto2 ? this.texto2 : "",
        FisicaMoralId: this.general.FisicaMoralId,
        Controversiaarrendamiento: this.controversias ? true : false,
        Hipotecas: this.hipoteca ? true : false,
        ProcesosPenales: this.procesosP ? true : false,
        AlertasMedios: this.alertas ? true : false,
        EjecutivoMercantil: this.ejecutivo ? true : false,
      };
      console.log(this.controversias);
      if (this.id > 0) {
        servInv
          .put(this.id, datosRi)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          })
          .catch((e) => {
            this.visible = false; 
            mensaje.Error("ReporteInvestigacion-crud-put", e);
          });
      } else {
        servInv
          .post(datosRi)
          .then((r) => {
            this.ProcesamosResponse(r.data);
          })
          .catch((e) => {
            this.visible = false; 
            mensaje.Error("ReporteInvestigacion-crud-post", e);
          });
      }
    },
    getDatos() {
      if (this.general.FisicaMoralId) {
        servInv
          .getId(this.general.FisicaMoralId)
          .then((r) => this.Asignamos(r.data))
          .catch((e) => console.log("Error - fisicaMoral", e));
        servFM
        .getId(this.general.SolicitudId)
        .then((r) => { 
          this.AsignamosFm(r.data)
          this.dataFm = r.data;  
        })
        .catch((e) => console.log("Error - fisicaMoral", e));  
      } else {
        console.error("El fisicaMoralId es undefined o null");
      }
    },

    Asignamos(r) {
      this.id = r.id;
      this.texto1 = r.texto1;
      this.texto2 = r.texto2;
    },
    AsignamosFm(r){
      this.controversias = r.controversiaarrendamiento;
      this.hipoteca = r.hipotecas;
      this.procesosP = r.procesosPenales;
      this.alertas = r.alertasMedios;
      this.ejecutivo =  r.ejecutivoMercantil;
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },

    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.visible = false;
            this.notifyVue(m);
            break;
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje];
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.visible = false;
            this.notifyVue(m);
            break;
          case 3: //success
            this.getDatos();
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.visible = false;
            this.notifyVue(m);
            break;
          case 4: //Information
            m.mensaje = e.mensaje;
            m.tipo = "info";
            this.notifyVue(m);
            break;
        }
      });
    },
  },

  created() {
    console.log("fisicaMoralId", this.general.FisicaMoralId);
    this.getDatos();
  },
};
</script>

<style lang="css" scoped>
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) ;
}
.loader:before {
  content:"Guardando....."
}
</style>
