import { http } from "@/http-commons";

class FisicaMoral {
  getId(id) {
    return http.get(`/FisicaMoral/${id}`);
  }
  getRegimen(id){
    return http.get(`/FisicaMoral/Regimen/${id}`)
  }
  delete(id){
    return http.delete(`/FisicaMoral/${id}`);
  }
  post (data){
    return http.post(`/FisicaMoral`,data);
  }
  put(id,data){
    return http.put(`/FisicaMoral/${id}`,data)
  }
}

export default new FisicaMoral();