import { render, staticRenderFns } from "./personasFisicasFiador.vue?vue&type=template&id=38c312b7&scoped=true"
import script from "./personasFisicasFiador.vue?vue&type=script&lang=js"
export * from "./personasFisicasFiador.vue?vue&type=script&lang=js"
import style0 from "./personasFisicasFiador.vue?vue&type=style&index=0&id=38c312b7&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38c312b7",
  null
  
)

export default component.exports