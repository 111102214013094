<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Cuentas</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableData">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id" v-if="isDebug">{{
                item.cuentaId
              }}</md-table-cell>
              <md-table-cell md-label="Nombre">{{ item.nombre }}</md-table-cell>
              <md-table-cell md-label="Banco">{{ item.banco }}</md-table-cell>
              <md-table-cell md-label="Saldo" v-if="usuario.areaId == 1">{{
                item.saldo
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-50">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-blue">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Recibo</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableDataMovimientosM">
            <md-table-toolbar>
              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Buscar por Id"
                  v-model="MMId"
                >
                </md-input>
                <md-field>
                  <md-button
                    class="md-default"
                    @click="getMovimientosMaeestro()"
                    >Buscar</md-button
                  >
                </md-field>
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id">{{ item.id }}</md-table-cell>
              <md-table-cell md-label="Cuenta">{{
                item.cuenta.nombre
              }}</md-table-cell>
              <md-table-cell md-label="Entrada">{{
                item.entrada
              }}</md-table-cell>
              <md-table-cell md-label="Salida">{{ item.salida }}</md-table-cell>
              <md-table-cell md-label="Fecha">{{ item.fecha }}</md-table-cell>
              <md-table-cell md-label="Creado por">{{
                item.usuario.usuarioNomCompleto
              }}</md-table-cell>
              <md-table-cell
                md-label="Confirmado por"
                v-if="item.confirmacion != undefined"
                >{{ item.confirmacion.usuarioNomCompleto }}</md-table-cell
              >
              <md-table-cell md-label="Confirmado por" v-else
                >Pendiente de Confirmación</md-table-cell
              >
              <md-table-cell md-label="Acciones">
                <md-button
                  class="md-just-icon md-success md-round"
                  @click="GenerarRecibo(item.id)"
                  ><md-icon>get_app</md-icon></md-button
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Movimientos</h4>
        </md-card-header>
        <md-card-content>
          <md-table v-model="tableDataMovimientos">
            <md-table-toolbar>
              <md-progress-bar
              class="md-warning"
              md-mode="buffer"
              v-if="isLoader == true"
            ></md-progress-bar>
              <md-field class="text-right">
                <md-button class="md-warning" 
                @click="ShowModal(2)"
                  >Confirmación de Movimientos</md-button
                >
                <md-button class="md-primary" @click="ShowModal(1)"
                  >Generar Movimientos</md-button
                >
              </md-field>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <!-- <md-table-cell md-label="Id">{{ item.id }}</md-table-cell> -->
              <!-- <md-table-cell md-label="Id Padre">{{
                item.idPadreNavigation
              }}</md-table-cell> -->
              <!-- <md-table-cell md-label="Recibo">{{
                item.mmid
              }}</md-table-cell> -->
              <md-table-cell
                md-label="Detalle Póliza"
                v-if="item.detallePolizaId > 0"
                >Id Póliza: {{ item.detallePoliza.polizaId }} - Concepto:
                {{ item.detallePoliza.categorias.descripcion }} - Importe:
                {{ item.detallePoliza.importe }}- Costo:
                {{ item.detallePoliza.costo }}- Estatus:
                {{ item.detallePoliza.estatus }}</md-table-cell
              >
              <md-table-cell md-label="Detalle Póliza" v-else
                >N/A</md-table-cell
              >
              <md-table-cell md-label="Transferencias" v-if="item.dmid > 0"
                >Importe: {{ item.dm.importe }} - Referencia:
                {{ item.dm.referencias }}
              </md-table-cell>
              <md-table-cell md-label="Transferencias" v-else
                >N/A
              </md-table-cell>
              <md-table-cell
                md-label="Cuentas X Pagar"
                v-if="usuario.areaId == 1"
                >{{ item.cpid }}</md-table-cell
              >
              <md-table-cell
                md-label="Cuentas X Cobrar"
                v-if="usuario.areaId == 1"
                >{{ item.ccid }}</md-table-cell
              >
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
    <modal v-if="classicModal" @close="classicModalHide">
      <template slot="header">
        <h4 class="modal-title">{{ datosModal.titulo }}</h4>
        <md-button
          class="md-simple md-just-icon md-round modal-default-button"
          @click="classicModalHide"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body" v-if="datosModal.evento == 1">
        <md-field :class="cuentaIdError">
          <label for="AreaId">(Seleccionar cuenta)</label>
          <md-select v-model="cuentaId">
            <md-option
              v-for="c in tableData"
              :key="c.cuentaId"
              :value="c.cuentaId"
            >
              {{ c.nombre }}
            </md-option>
          </md-select>
        </md-field></template
      >
      <template slot="body" v-if="datosModal.evento == 2">
        <h3>¿Que desea realizar con el siguiente recibo?</h3>
        <h4>
          Al confirmar, todas las polizas que se encuentren en el corte se
          pondrán en estatus de confirmado; pero si cancela el recibo se elimina
          el corte.
        </h4>
      </template>
      <template slot="footer">
        <md-button class="md-danger md-simple" @click="classicModalHide"
          >Cerrar</md-button
        >
        <md-button
          class="md-danger"
          @click.native="CancelarAccion()"
          v-if="datosModal.evento == 2"
          >Cancelar</md-button
        >
        <md-button 
        class="md-success" 
        @click.native="Accion()"
      
        >{{
          datosModal.boton
        }}</md-button>
      </template>
    </modal>
  </div>
</template>
<script>

import servcuentas from "@/Servicios/Movimientos/cuentas";
import servmovimientomaestro from "@/Servicios/Movimientos/movimientosmaestro";
import servmovimiento from "@/Servicios/Movimientos/movimientos";
import { Modal } from "@/components";
import store from "@/Store";
import servContabilidad from "@/Servicios/Contabilidad/contabilidad";
import excel from "@/Utilerias/GenerarExcel";

export default {
  components: {
    Modal,
  },
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      classicModal: false, //Modal
      tableData: [],
      tableDataMovimientosM: [],
      tableDataMovimientos: [],
      MMId: 0,
      cuentaIdError: "",
      cuentaId: 0,
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      cantidadDatosTable: 0,
      mens: {
        tipo: "",
        mensaje: "",
      },
      isDebug: false,
      isLoader: false,
      disabledButton : false,
      
    };
  },
  methods: {
    ConfirmDeleteCorte(id) {
      servContabilidad
        .ConfirmDeleteCorte(this.MMId, this.usuario.usuariosId, id)
        .then((r) => {
          this.ProcesamosResponse(r.data);
        })
        .catch((e) =>
          console.log(
            "Se genero un error al momento de generar Confirmacion/cancelacion: ",
            e
          )
        );
    },
    CancelarAccion() {
      this.ConfirmDeleteCorte(2);
    },
    Accion() {
      switch (this.datosModal.evento) {
        case 1:
          this.movimientos();
          break;
        case 2:
            this.ConfirmDeleteCorte(1);
            this.disabledButton = true;
          }
      },
    ShowModal(id) {
      
      this.datosModal.evento = id;
      switch (id) {
        case 1:
          this.classicModal = true;
          this.datosModal.titulo = "Seleccionar Cuenta:";
          this.datosModal.boton = "¡Vamos!";
          break;
        case 2:
          if (this.cantidadDatosTable > 0) {
            this.datosModal.titulo = "Confirmar/ Cancelar Corte:";
            this.datosModal.boton = "Confirmar";
            this.classicModal = true;
          } else {
            this.mens.mensaje =
              "Para poder Confirmar/Cancelar un corte es necesario tener movimientos en el corte";
            this.mens.tipo = "danger";
            this.notifyVue();
          }
          break;
      }
    },
    GenerarRecibo(id) {
      servContabilidad
        .get(id)
        .then((r) => {
          excel.GeneraExcelMovimientos(r.data);
        })
        .catch((e) => {
          console.log("Error en generar el recibo", e);
        });
    },
    classicModalHide() {
      this.classicModal = false;
      //this.disabledButton = false; 
    },
    getDatos() {
      console.log("get cuentas - entro")
      servcuentas
        .Usuario(this.usuario.usuariosId,true)
        .then((r) => {
          console.log("Cuentas de Moviemintos", r.data );
          this.tableData = r.data;
        })
        .catch((e) => {
          console.log("Error en movientos en getCuentas all", e);
        });
    },
    getMovimientosMaeestro() {
      if (this.MMId > 0) {
        this.tableDataMovimientosM = [];
        this.tableDataMovimientos = [];
        servmovimientomaestro
          .get(this.MMId, this.usuario.usuariosId)
          .then((r) => {
            if (r.data != undefined) {
              this.tableDataMovimientosM.push(r.data);
              this.getMovimientos();
              this.isLoader = true;
            } else {
              this.mens.mensaje = "Sin resultados";
              this.mens.tipo = "info";
              this.notifyVue();
            }
          })
          .catch((e) => {
            console.log("Error en movientos en getCuentas all", e);
            this.mens.mensaje = "Sin resultados";
              this.mens.tipo = "info";
              this.notifyVue();
          });
      } else {
        this.mens.mensaje = "Favor de ingresar el número de recibo";
        this.mens.tipo = "danger";
        this.notifyVue();
      }
    },
    getMovimientos() {      
      servmovimiento
        .get(this.MMId)
        .then((r) => {
          //console.log(r.data);
          this.tableDataMovimientos = r.data;
          this.cantidadDatosTable =
            this.tableDataMovimientos == null ||
            this.tableDataMovimientos == undefined ||
            this.tableDataMovimientos.length <= 0
              ? 0
              : this.tableDataMovimientos.length;
          //console.log(this.cantidadDatosTable);
          this.isLoader = false;
        })
        .catch((e) => {
          console.log("Error en movientos en getCuentas all", e);
          this.isLoader = false;
        });
    },
    movimientos() {
      if (this.cuentaId > 0) {
        let Cuenta = {
          Id: 0,
          Nombre: null,
          Saldo: 0,
          entrada: 0,
          salida: 0,
          total: 0,
        };
        store.state.Cuenta = Cuenta;
        this.tableData.forEach((c) => {
          if (c.cuentaId == this.cuentaId) {
            Cuenta.Id = c.cuentaId;
            Cuenta.Nombre = c.nombre;
            Cuenta.Saldo = c.saldo;
            store.state.Cuenta = Cuenta;
          }
        });

        this.$router.push({
          name: "movimientoscrud",
          params: { id: this.cuentaId },
        });
      } else {
        this.mens.mensaje = "Favor de seleccionar una cuenta";
        this.mens.tipo = "danger";
        this.notifyVue();
      }
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        switch (e.tipo) {
          case 2: //Atributos por completar
            // se agrega a la clase el error "md-valid" ó "md-error"
            this[e.mensaje] = "md-error";
            break;
          case 1: //Error
            this.classicModalHide();
            this.isActivo = false;
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "danger";
            this.notifyVue(this.mens);
            break;
          case 3: //success
            this.classicModalHide();
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "success";
            this.notifyVue(this.mens);
            this.getMovimientosMaeestro();
            break;
          case 4: //Information
            this.mens.mensaje = e.mensaje;
            this.mens.tipo = "info";
            this.notifyVue(this.mens);
            break;
          case 5: //Parametros
            this.transId = parseInt(e.mensaje);
            break;
        }
      });
    },
    notifyVue() {
      this.$notify({
        message: this.mens.mensaje,
        timeout: 1000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: this.mens.tipo,
      });
    },    
 },
 created() {
    this.getDatos();
    console.log("get cuentas - salio")
  }
};
</script>

<style lang="css" scoped>
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
