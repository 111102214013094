import { http } from "@/http-commons";

class Tramites {
    getall(){
        return http.get(`/Tramites`);
    }
    getId(id) {
        return http.get(`/Tramites/${id}`);
    }
    delete(id) {
        return http.delete(`/Tramites/${id}`);
    }
    post(data) {
        return http.post(`/Tramites`, data);
    }
    put(id) {
        return http.put(`/Tramites/${id}`);
    }
    getUsuario(id){
        return http.get(`/Tramites/Representantes/${id}`);
    }
}
export default new Tramites();