<template>
  <div class="md-layout-item md-size-100">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-rose">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
        <h4 class="title">Detalle de Póliza</h4>
      </md-card-header>
      <md-card-content>
        <md-table v-model="tableDataDP">
          <md-table-toolbar>
            <md-field class="text-right">
              <md-field>
                <label for="categoriaESId">Seleccionar Categoria</label>
                <el-tree :data="dataCategoria" :props="defaultProps" @node-click="handleNodeClick"
                  highlight-current></el-tree>
              </md-field>
              <md-button class="md-primary" @click="Modalpersonalizado(1)">Seleccionar</md-button>
            </md-field>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Id" v-if="isDebug">{{
              item.detallePolizaId
            }}</md-table-cell>
            <md-table-cell md-label="Póliza Id">{{
              item.polizaId
            }}</md-table-cell>
            <md-table-cell md-label="concepto">{{
              item.categorias.descripcion
            }}</md-table-cell>
            <md-table-cell md-label="Importe">{{ item.importe }}</md-table-cell>
            <md-table-cell md-label="Costo Poliza">{{ item.costo }}</md-table-cell>
            <md-table-cell md-label="Acciones">
              <md-button class="md-just-icon md-danger md-round"
                @click="Eliminar(item)"><md-icon>close</md-icon></md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
    <modal v-if="classicModal" @close="classicModalHide">
      <template slot="header">
        <h4 class="modal-title">{{ datosModal.titulo }}</h4>
        <md-button class="md-simple md-just-icon md-round modal-default-button" @click="classicModalHide">
          <md-icon>clear</md-icon>
        </md-button>
      </template>
      <template slot="body">
        <md-card-content>
          <md-table :value="queriedData" :md-sort.sync="currentSort" :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort" class="paginated-table table-striped table-hover" @md-selected="onSelect">
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por página</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar registro"
                  v-model="searchQuery">
                </md-input>
              </md-field>
              <md-field>
                <md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Buscar poliza"
                  v-model="searchPolizaId">
                </md-input>
              </md-field>
              <md-field>
                <md-button class="md-primary" @click="buscarXPoliza()">Buscar</md-button>
              </md-field>
            </md-table-toolbar>
            <md-progress-bar class="md-warning" md-mode="buffer" v-if="isLoader == true"></md-progress-bar>
            <md-table-row slot="md-table-row" slot-scope="{ item }" md-selectable="multiple">
              <md-table-cell md-label="Id" md-sort-by="detallePolizaId" v-if="isDebug">{{
                item.detallePolizaId
              }}</md-table-cell>
              <md-table-cell md-label="Póliza Id">{{
                item.polizaId
              }}</md-table-cell>
              <md-table-cell md-label="concepto">{{
                item.categorias.descripcion
              }}</md-table-cell>
              <md-table-cell md-label="Importe">{{ item.importe }}</md-table-cell>
              <md-table-cell md-label="Costo">{{ item.costo }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
            :per-page="pagination.perPage" :total="total">
          </pagination>
        </md-card-actions>
      </template>
      <template slot="footer">
        <md-button class="md-danger md-simple" @click="classicModalHide">Cerrar</md-button>
        <md-button class="md-primary" @click="movimientos()">Agregar</md-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Pagination, Modal } from "@/components";
import serdetallepoliza from "@/Servicios/Polizas/detallepoliza";
import Fuse from "fuse.js";
import store from "@/Store/index";
import servCategoria from "@/Servicios/Administracion/categorias";

export default {
  components: {
    Modal,
    Pagination,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    cuenta() {
      return store.state.Cuenta;
    },
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      dataCategoria: [],
      classicModal: false, //Modal
      tableData: [], //Detalle Poliza Modal
      tableDataDP: [], //Detalle de Póliza
      datosModal: {
        titulo: "",
        boton: "",
        evento: 0,
      },
      searchQuery: "",
      currentSort: "detallePolizaId",
      currentSortOrder: "asc",
      isLoader: true,
      searchedData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10],
        total: 0,
      },
      selected: [],
      mens: {
        tipo: "",
        mensaje: "",
      },
      searchPolizaId: 0,
      isDebug: false,
      categoriaESId: 0,
      tableCategoria: [], //Categorias
      categoriaId: 0,
    };
  },
  methods: {
    obtenemosCategorias() {
      servCategoria
        .categoriapormodulo(true, false)
        .then((response) => {
          response.data.forEach((v) => {
            //esta funcionalidad carga el arbol
            if (v.categoriaEspadreId == null) {
              let padre = {
                id: v.categoriaEsid,
                label: v.descripcion,
                children: [],
              };
              v.inverseCategoriaEspadre.forEach((c) => {
                let children = {
                  id: c.categoriaEsid,
                  label: c.descripcion,
                };
                padre.children.push(children);
              });
              this.dataCategoria.push(padre);
            }
          });
        })
        .catch(function (e) {
          mensaje.Error("categoriapormodulo-detallepolizas-then", e);
        });
    },
    Eliminar(item) {
      //console.log(item);
      let findIndex = this.tableDataDP.findIndex(
        (a) => a.detallePolizaId === item.detallePolizaId
      );
      if (findIndex !== -1) {
        this.tableDataDP.splice(findIndex, 1);
      }
      this.mens.tipo = "success";
      this.mens.mensaje = "Se han descartado correctamente el registro";
      this.notifyVue(this.mens);
      store.state.Movimientos.DetallePolizas = this.tableDataDP;
    },
    onSelect(items) {
      this.selected = [];
      this.selected = items;
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    movimientos() {
      if (this.selected.length > 0) {
        let DT = [];
        DT = this.tableData;
        let Entrada = 0;
        let Salida = 0;
        this.selected.forEach((i) => {
          this.tableDataDP.push(i);
          let findIndex = DT.findIndex(
            (a) => a.detallePolizaId === i.detallePolizaId
          );
          if (findIndex !== -1) {
            DT.splice(findIndex, 1);
          }
        });
        this.tableData = [];
        this.tableData = DT;
        /* Sumamos entradas y salidas*/
        this.tableDataDP.forEach((t) => {
          if (t.categorias.tipoEs == 1) {
            Entrada = t.importe + Entrada;
            console.log("Entrada" + Entrada);
          }
          if (t.categorias.tipoEs == 2) {
            Salida = t.importe + Salida;
            console.log("Salida" + Salida);
          }
        });
        store.state.Cuenta.entrada = Entrada;
        store.state.Cuenta.salida = Salida;
        store.state.Movimientos.DetallePolizas = this.tableDataDP;
        this.mens.tipo = "success";
        this.mens.mensaje = "Se han agregado los archivos seleccionados";
        this.notifyVue(this.mens);
        this.classicModalHide();
      } else {
        this.mens.tipo = "danger";
        this.mens.mensaje =
          "Para continuar tiene que seleccionar almenos un detalle de póliza";
        this.notifyVue(this.mens);
      }
    },
    classicModalHide() {
      this.classicModal = false;
    },
    Modalpersonalizado(tipo) {
      switch (tipo) {
        case 1: //Detalle de póliza
          this.GetAll();
          this.datosModal.titulo = "Listado de Detalle de Pólizas";
          this.classicModal = true;
          this.datosModal.evento = tipo;
          break;
      }
    },
    defaultProps() {},
    async GetAll() {    
      serdetallepoliza
        .MoviDetallePolizas(this.usuario.usuariosId,this.categoriaId)
        .then((r) => {
          this.tableData = r.data;
          if (this.tableDataDP.length > 0) {
            this.tableDataDP.forEach((dp) => {
              let findIndex = this.tableData.findIndex(
                (a) => a.detallePolizaId === dp.detallePolizaId
              );
              if (findIndex !== -1) {
                this.tableData.splice(findIndex, 1);
              }
            });
          }
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.tableData, {
            keys: ["polizaId", "categorias.descripcion"],
            threshold: 0.3,
          });
          this.isLoader = false;
        })
        .catch((e) => {
          console.log("detalle-polizas-movimientos-GETALL");
          console.log(e);
          this.isLoader = false;
        });
    },
    buscarXPoliza() {
      if (this.searchPolizaId > 0) {
        serdetallepoliza
          .MoviDetallePolizasId(this.usuario.usuariosId, this.searchPolizaId)
          .then((r) => {
            console.log("MoviDetallePolizasId", r.data);
            if (r.data) {
              this.tableData = r.data;
              if (this.tableDataDP.length > 0) {
                this.tableDataDP.forEach((dp) => {
                  let findIndex = this.tableData.findIndex(
                    (a) => a.detallePolizaId === dp.detallePolizaId
                  );
                  if (findIndex !== -1) {
                    this.tableData.splice(findIndex, 1);
                  }
                });
              }
              // Fuse search initialization.
              this.fuseSearch = new Fuse(this.tableData, {
                keys: ["polizaId", "categorias.descripcion"],
                threshold: 0.3,
              });
              this.isLoader = false;
            } else {
              this.mens.tipo = "danger";
              this.mens.mensaje = "No se encontro la póliza";
              this.notifyVue(this.mens);
            }
          })
          .catch((e) => {
            console.log("detalle-polizas-movimientos-GETALL");
            console.log(e);
            this.isLoader = false;
          });
      } else {
        this.mens.tipo = "info";
        this.mens.mensaje = "Favor de ingresar el número de póliza";
        this.notifyVue(this.mens);
      }
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 1000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    handleNodeClick(data) {
      this.categoriaId = data.id;
    },
  },
  created() {
    this.obtenemosCategorias();
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map(r => r.item);
    },
  },
};
</script>

<style></style>