import { http } from "@/http-commons";
import { HubConnectionBuilder,HttpTransportType } from '@microsoft/signalr';

class DocumentoPlantilla {
  constructor() {
    this.connection = null;
    this.connectionId = null;
  }

  getId(id) {
    return http.get(`/Documentos/${id}`);
  }

  all() {
    return http.get(`/DocumentoPlantilla`);
  }

  getContrato(FisicaMoral, ContratoId, UsuarioID) {
    return http.get(`/GenerarContrato/${FisicaMoral}/${ContratoId}/${UsuarioID}`);
  }

  getObservaciones(observacionesId) {
    return http.get(`/Observaciones/${observacionesId}`);
  }

  async initWebSocket() {
    try {
      var URLBASE = process.env.VUE_APP_HTTP_BASE
      this.connection = new HubConnectionBuilder()
        .withUrl(`${URLBASE}/contratoHub`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets
        }) // Ajusta al puerto de tu API
        .withAutomaticReconnect()
        .build();

      // Set up event handlers before starting the connection
      this.connection.on("Connected", (connectionId) => {
        this.connectionId = connectionId;
        console.log("Connected to hub with ID:", connectionId);
      });

      this.connection.on("ProgressUpdate", ({ message, progress }) => {
        console.log(`Progress: ${progress}% - ${message}`);
        // You can emit an event here if needed
      });

      this.connection.on("ErrorOccurred", (error) => {
        console.error("Hub error:", error);
        // Handle error appropriately
      });

      await this.connection.start();
      return this.connection;
    } catch (error) {
      console.error("Error establishing SignalR connection:", error);
      throw error;
    }
  }

  async getContratoIA(FisicaMoral, ContratoId, UsuarioID) {
    try {
      if (!this.connection || this.connection.state !== "Connected") {
        await this.initWebSocket();
        // Esperar a que se establezca la conexión
        await new Promise(resolve => {
          this.connection.on("Connected", (id) => {
            this.connectionId = id;
            resolve();
          });
        });
      }
  
      return await http.get(
        `/GenerarContrato/ia/${FisicaMoral}/${ContratoId}/${UsuarioID}/${this.connectionId}`
      );
    } catch (error) {
      console.error("Error in getContratoIA:", error);
      throw error;
    }
  }
}

export default new DocumentoPlantilla();